<template>
  <div class="home">
    <!-- 头部组件 -->
    <nav-header ref="headers" @handleLogin="handleLogin" />
    <!-- 转换 -->
    <convert-list :isLogin="isLogin" :convertType="convertType" />
    <!-- 数据展示和评分 -->
    <handle-or-score />
    <!-- 底部 -->
    <copy-right-footer />
  </div>
</template>

<script>
import NavHeader from "@/components/NavHeader";
import ConvertList from "@/components/ConvertList";
import HandleOrScore from "@/components/HandleOrScore";
import CopyRightFooter from "@/components/CopyRightFooter";
import { menuList } from "@/utils/menu";
export default {
  components: {
    NavHeader,
    ConvertList,
    CopyRightFooter,
    HandleOrScore
  },
  data() {
    return {
      convertType: {},
      isLogin: JSON.parse(localStorage.getItem("isLogin")) || false
    }
  },
  created() {
    const name = this.$route.path;

    menuList.forEach(item => {
      if (item.href === name) {
        this.convertType = { ...item };
      }
    });
  },
  methods: {
    handleLogin(value) {
      this.isLogin = value;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
