<template>
  <div class="convert-wrap">
    <div class="convert-content">
      <!-- 左侧菜单 -->
      <div class="convert-left">
        <div class="convert-list-wrap">
          <div class="convert-left-line"></div>
          <div class="convert-left-title">PDF转换</div>
          <div
            :class="[
              'convert-left-list',
              convertType.id === item.id ? 'list_active' : ''
            ]"
            v-for="item in menuList.slice(0, 4)"
            :key="item.id"
            @click="listClick(item)"
          >
            <img :src="item.list_icon" alt="" />
            {{ item.name }}
          </div>
        </div>
        <div class="convert-list-wrap">
          <div class="convert-left-line"></div>
          <div class="convert-left-title">文档转PDF</div>
          <div
            :class="[
              'convert-left-list',
              convertType.id === item.id ? 'list_active' : ''
            ]"
            v-for="item in menuList.slice(4, 10)"
            :key="item.id"
            @click="listClick(item)"
          >
            <img :src="item.list_icon" alt="" />
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 右侧转换区域 -->
      <div class="convert-right">
        <!-- 标题 -->
        <div class="convert-right-title">
          <div class="convert-right-title-left">
            <h3 class="convert-right-title-left-name">
              {{ convertType.name }}
            </h3>
            <span class="convert-right-title-left-tips"
              >文件过大，页数过多时，转换时间较长，可过后访问本站，查看转换记录（仅保留30天）</span
            >
          </div>
          <div class="convert-right-title-right">
            <!-- 转换记录按钮 -->
            <div
              class="convert-right-title-btn"
              v-if="isLogin"
              @click="toHistory"
            >
              转换记录
            </div>
            <!-- 添加文件按钮 -->
            <el-upload
              v-if="upFlag === 2"
              ref="upload"
              :action="action"
              :headers="headers"
              :show-file-list="false"
              :accept="accept"
              :http-request="httpRequest"
              :before-upload="beforeUpload1"
            >
              <div class="convert-right-title-btn btn-file">添加文件</div>
            </el-upload>
          </div>
        </div>
        <!-- 上传前 -->
        <div class="convert-update-wrap" v-if="upFlag === 1">
          <div class="convert-update">
            <div class="convert-update-icon">
              <img :src="convertType.pic" alt="" />
            </div>
            <el-upload
              :action="action"
              :headers="headers"
              :show-file-list="false"
              :accept="accept"
              :http-request="httpRequest"
              :before-upload="beforeUpload"
            >
              <div class="convert-update-btn">
                <i class="el-icon-plus"></i>
                点击上传
              </div>
            </el-upload>
            <div class="convert-update-tips">最大支持100MB文件</div>
          </div>
        </div>
        <!-- 上传中 -->
        <div class="convert-update-wait" v-else-if="upFlag === 0">
          <div class="wait-icon">
            <img src="../assets/img/home/upload/wait.png" alt="" />
          </div>
          <div class="wait-desc">正在上传文件...</div>
        </div>
        <!-- 上传失败 -->
        <div class="convert-update-error" v-else-if="upFlag === -1">
          <div class="wait-icon">
            <img src="../assets/img/home/upload/error.png" alt="" />
          </div>
          <div class="wait-desc err">上传失败</div>
          <!-- <div class="big-btn" @click="uploadAgain">重新上传</div> -->
          <el-upload
            :action="action"
            :headers="headers"
            :show-file-list="false"
            :accept="accept"
            :http-request="httpRequest"
            :before-upload="beforeUpload1"
          >
            <div class="big-btn">重新上传</div>
          </el-upload>
        </div>
        <!-- 开通会员 -->
        <div class="convert-update-vip big" v-else-if="upFlag === -3">
          <div class="big-left">
            <img
              style="height: 100px"
              src="../assets/img/home/upload/big.png"
              alt=""
            />
          </div>
          <div class="big-right">
            <div class="big-title">上传文件过大，超出非会员用户限制</div>
            <div class="big-desc">
              您刚刚选择的文件已超过最大允许文件大小10M，暂未完成添加。<br />
              如果您需要对10M以上文件进行操作，您可以加入 PDF在线转换会员
              享受无限制服务。
            </div>
            <div class="big-btn-desc-wrap">
              <el-upload
                ref="upload"
                :action="action"
                :headers="headers"
                :show-file-list="false"
                :accept="accept"
                :http-request="httpRequest"
                :before-upload="beforeUpload"
              >
                <div class="big-btn bit-btn-v">重新上传</div>
              </el-upload>
              <span class="big-btn-desc" @click="openVip">
                >>>了解会员权益</span
              >
            </div>
          </div>
        </div>
        <!-- 转换列表 -->
        <div class="convert-update-list" v-else-if="upFlag === 2">
          <div class="convert-update-list-title">
            <div class="t1">文件名</div>
            <div class="t2">大小</div>
            <div class="t3">操作</div>
          </div>
          <div class="convert-update-list-item-wrap">
            <div
              class="convert-update-list-item"
              v-for="(item, index) in fileList"
              :key="index"
            >
              <!-- 文件名 -->
              <div class="i1">
                <div class="i-icon">
                  <img :src="convertType.c_icon" alt="" />
                </div>
                <div class="i-name">{{ item.raw.name }}</div>
              </div>
              <!-- 文件大小 -->
              <div class="i2">{{ item.raw.sizes }}</div>
              <!-- 文件上传&转换状态 -->
              <div class="i3">
                <div class="i-status" v-show="item.raw.textType === 0">
                  等待转换
                </div>
                <div class="i-status" v-show="item.raw.textType === 1">
                  转换完成
                </div>
                <div
                  class="i-status i-status-err"
                  v-show="item.raw.textType === 2"
                >
                  转换失败
                </div>
                <div class="i-status" v-show="item.raw.textType === 3">
                  正在转换
                </div>
                <div class="i-status" v-show="item.raw.textType === 8">
                  正在上传
                </div>
                <div
                  class="i-status i-status-err"
                  v-show="item.raw.textType === -8"
                >
                  上传失败
                </div>
                <!-- 上传进度条 -->
                <div
                  class="i-progress"
                  v-show="item.raw.handleType === 0 && item.raw.preFlag === 1"
                >
                  <el-progress
                    :show-text="false"
                    :percentage="item.percentage"
                  ></el-progress>
                </div>
                <!-- 转换进度条 -->
                <div
                  id="zhjdt"
                  class="i-progress"
                  v-show="item.raw.handleType === 0 && item.raw.preFlag === 2"
                >
                  <!-- <el-progress
                    :show-text="false"
                    :percentage="item.percentage"
                  ></el-progress> -->
                </div>
                <!-- 转换结果 -->
                <div
                  class="i-download-f"
                  v-show="item.raw.handleType === 1"
                  @click="downloadFile(item)"
                >
                  下载文件
                </div>
                <a
                  class="i-download-c"
                  v-show="item.raw.handleType === 2"
                  href="http://file.zsincer.com/pack/pdf/jk/jkpdf_guanf.exe"
                >
                  下载客户端
                </a>
                <!-- <div class="i-upload-again" v-show="item.raw.handleType === -1">重新上传</div> -->
                <!-- <div v-show="item.raw.handleType === -1"></div> -->
                <el-upload
                  v-show="item.raw.handleType === -1"
                  :action="action"
                  :headers="headers"
                  :show-file-list="false"
                  :accept="accept"
                  :http-request="httpRequest"
                  :before-upload="beforeUpload"
                >
                  <div class="i-upload-again">重新上传</div>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
        <!-- 提示 -->
        <div class="convert-tip">
          <div class="convert-tip-top">
            注意：如果PDF有密码保护，请先去除加密保护再上传文件
          </div>
          <div class="convert-tip-bottom">
            <span class="convert-tip-bottom-text"
              >非会员用户转换大小仅限于10M以内文件，如需转换更大文件、转换更多格式文件，请</span
            >
            <div class="convert-tip-bottom-btn open-vip-btn" @click="openVip">
              开通会员
            </div>
            <span class="convert-tip-bottom-text">或</span>
            <a
              href="http://file.zsincer.com/pack/pdf/jk/jkpdf_guanf.exe"
              class="convert-tip-bottom-btn download-client"
              >下载客户端</a
            >
          </div>
        </div>
      </div>
      <!-- tost提示 -->
      <transition name="el-fade-in-linear">
        <div class="tost" v-show="showTost">{{ tostText }}</div>
      </transition>
    </div>
  </div>
</template>

<script>
import { menuList } from "@/utils/menu";
import { fileTypes } from "./const";
import { hxsGet } from "@/apis/index";
import axios from "axios";
// import { transFiles } from "@/apis/trans";
// import { readFile } from "@/utils/readfile";
// import { commonParams } from "@/utils/publicParam";
export default {
  props: {
    convertType: {
      type: Object,
      default() {
        return {
          id: 1,
          name: "PDF转Word", // 名称
          pic: require("@/assets/img/trans/t/pdf-word.png"), // 转换图标
          list_icon: require("@/assets/img/home/list/i1.png"), // 转换区域右侧列表图标
          c_icon: require("@/assets/img/home/upload/i-pdf.png"), // 上传&转换文件名前面的icon
          href: "/pdf-to-word", // 页面路由
          type: 4, // 转换类型，转换接口传给后端
          last: "doc", // 转换结果的文件后缀
          download: 1, // 下载类型，1单文件下载，2压缩包下载
          isHot: false // 是否是当前热门
        };
      }
    }
  },
  data() {
    return {
      menuList,
      action: "",
      accept: "",
      fileList: [],
      headers: {
        token: window.localStorage.getItem("token")
      },
      // 上传状态 1上传文件初始状态 0上传中 -1上传失败 -2过大下载客户端 -3过大充值会员  2上传成功列表
      upFlag: 1,
      isLogin: localStorage.getItem("isLogin"),
      tostText: "",
      showTost: false,
      changeNum: 0
      // convertTimer: null,
      // statusTimer: null,
    };
  },
  created() {
    const path = this.$route.name;

    this.accept = fileTypes[path]["accept"];
    this.action = `${process.env.VUE_APP_API_HXS}${fileTypes[path]["action"]}`;
  },
  computed: {
    isILogin() {
      return this.$parent.$refs.headers.isLogin;
    }
  },
  watch: {
    isILogin(val, oldVal) {
      this.isLogin = val;
      if (val) {
        this.fileList.forEach((time) => {
          if (time.unboundTask) {
            hxsGet(
              "doconline/open/file/convert/binding.html?token=" +
                localStorage.getItem("token") +
                "&taskId=" +
                time.taskId
            ).then((res) => {
              time.time.unboundTask = false;
            });
          }
        });
      }
    }
  },
  methods: {
    // 上传data
    uploadData() {
      return {};
      //   const path = this.$route.name;
      //   return { type: fileTypes[path]["type"] };
    },
    // 左侧菜单点击
    listClick(item) {
      this.$router.push(item.href);
      this.fileList.forEach((item) => {
        if (item.statusTimer) {
          clearInterval(item.statusTimer);
        }
      });
    },
    // 第一个文件上传前，控制上传状态
    beforeUpload(file) {
      const files = file;

      files.preFlag = 1;
      files.textType = 8; // 上传&转换状态
      files.handleType = 0; // 显示进度条还是按钮
      files.sizes = this.changeSize(files.size); // 文件大小转换
      // 会员用户：上传不超过50M，如果超过50M，提示下载客户端，不超过50M正常上传
      // 非会员用户：上传不超过10M，如果超过10M，提示充值会员，不超过10M正常上传
      const vip = localStorage.getItem("isVip");
      const isSize = file.size / 1024 / 1024;

      if (vip === "true") {
        this.upFlag = 0;
      } else {
        if (isSize >= 10) {
          this.upFlag = -3;
          return false;
        } else {
          this.upFlag = 0;
        }
      }
    },
    // 第N个文件上传前，控制上传状态
    beforeUpload1(file) {
      const files = file;

      files.preFlag = 1;
      files.textType = 8; // 上传&转换状态
      files.handleType = 0; // 显示进度条还是按钮
      files.sizes = this.changeSize(files.size); // 文件大小转换
      // 会员用户：上传不超过50M，如果超过50M，提示下载客户端，不超过50M正常上传
      // 非会员用户：上传不超过10M，如果超过10M，提示充值会员，不超过10M正常上传
      const vip = localStorage.getItem("isVip");
      const isSize = file.size / 1024 / 1024;

      if (vip !== "true" && isSize >= 10) {
        this.upFlag = -3;
        return false;
      }
      const arr = [];

      this.fileList.forEach((item) => {
        if (item.onSc) {
          arr.push(item);
        }
      });
      if (arr.length > 4) {
        this.showTost = true;
        this.tostText = "每次最多同时转换5个文件，请耐心等待";
        const tostTime = setTimeout(() => {
          this.showTost = false;
          clearTimeout(tostTime);
        }, 3000);
        return false;
      }
    },
    // 上传中新方法
    onProgressz(key, file, jd) {
      let ifKey = true;

      this.fileList.forEach((item) => {
        if (key === item.key) {
          ifKey = false;
        }
      });
      if (ifKey) {
        this.fileList.push({
          percentage: jd,
          key: key,
          raw: {
            name: file.name,
            sizes: this.changeSize(file.size),
            textType: 8,
            handleType: 0,
            preFlag: 1
          }
        });
      }
      this.fileList.forEach((item) => {
        if (key === item.key) {
          item.onSc = true;
          item.percentage = jd;
        }
      });
    },
    // 新转换失败
    onErrorZ(key) {
      if (this.fileList.length > 1) {
        this.fileList.forEach((item) => {
          if (key === item.key) {
            item.raw.textType = -8;
            item.raw.handleType = -1;
            item.percentage = 0;
          }
        });
      } else {
        this.upFlag = -1;
        this.fileList = [];
      }
    },
    // 文件大小换算
    changeSize(fileByte) {
      const fileSizeByte = fileByte;
      let fileSizeMsg = "";

      if (fileSizeByte < 1048576) {
        fileSizeMsg = (fileSizeByte / 1024).toFixed(1) + "KB";
      } else if (fileSizeByte === 1048576) fileSizeMsg = "1MB";
      else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824) {
        fileSizeMsg = (fileSizeByte / (1024 * 1024)).toFixed(1) + "MB";
      } else if (fileSizeByte > 1048576 && fileSizeByte === 1073741824) {
        fileSizeMsg = "1GB";
      } else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776) {
        fileSizeMsg = (fileSizeByte / (1024 * 1024 * 1024)).toFixed(1) + "GB";
      } else fileSizeMsg = "文件超过1TB";
      return fileSizeMsg;
    },
    // 下载文件
    downloadFile(item) {
      if (localStorage.getItem("isLogin")) {
        hxsGet(
          "doconline/open/file/dld/presign.html?token=" +
            localStorage.getItem("token") +
            "&taskId=" +
            item.taskId
        ).then((res) => {
          if (res.success) {
            window.open(
              res.data.url +
                (res.data.url.indexOf("?") !== -1 ? "&" : "?") +
                "response-content-type=application%2Foctet-stream",
              "_self"
            );
          } else {
            if (res.code === "302000") {
              this.$confirm(res.msg, "提示", {
                confirmButtonText: "去购买",
                cancelButtonText: "我在想想",
                type: "warning"
              }).then(() => {
                this.$router.push("product");
              });
            } else {
              this.$message.error(res.msg);
            }
          }
        });
      } else {
        this.$parent.$refs.headers.OpenWxWebLogin();
      }
    },
    // 打开开通会员页
    openVip() {
      // const routeUrl = this.$router.resolve({
      //   path: "/product"
      // });

      // window.open(routeUrl.href, "_blank");
      this.$router.push("/product");
    },
    // 打开转换记录
    toHistory() {
      const routeUrl = this.$router.resolve({
        path: "/history"
      });

      window.open(routeUrl.href, "_blank");
      // this.$router.push("/history")
    },
    // 自定义上传
    httpRequest(file) {
      file = file.file;
      // 获取上传授权
      hxsGet(
        "doconline/open/file/upd/presign.html?token=" +
          localStorage.getItem("token") +
          "&fileName=" +
          encodeURIComponent(file.name) +
          "&fileSize=" +
          file.size
      ).then((res) => {
        // && 1 > 10000
        // 上传授权获取成功开始上传
        if (res.success) {
          axios
            .put(res.data.url, file, {
              headers: {
                "Content-Type": file.type
              },
              onUploadProgress: (progressEvent) => {
                // 上传过程
                this.onProgressz(
                  res.data.key,
                  file,
                  100 * (progressEvent.loaded / progressEvent.total)
                );
              }
            })
            .then((resPut) => {
              if (resPut.status === 200) {
                if (window._czc) {
                  window._czc.push([
                    "_trackEvent",
                    "DFP在线转换",
                    `${this.convertType.name}`,
                    "上传成功"
                  ]);
                }
                this.upFlag = 2;
                this.fileList.forEach((item) => {
                  if (res.data.key === item.key) {
                    item.raw.textType = 3;
                    item.raw.handleType = 0;
                    item.raw.preFlag = 2;
                    item.percentage = 0;
                    item.onSc = false;
                    // 进入转换
                    this.onZhuanHuan(item);
                  }
                });
              } else {
                this.onErrorZ(res.data.key);
              }
            })
            .catch(() => {
              this.onErrorZ(res.data.key);
            });
        } else {
          this.$message.success("上传准备错误请重新上传"); // 需要引入elemrnt
        }
      });
    },
    // 上传后转换任务发起，成功后绑定
    onZhuanHuan(item) {
      hxsGet(
        "doconline/open/file/convert.html?token=" +
          localStorage.getItem("token") +
          "&key=" +
          item.key +
          "&fileName=" +
          encodeURIComponent(item.raw.name) +
          "&type=" +
          fileTypes[this.$route.name]["zhType"]
      )
        .then((res) => {
          // 转换任务发送成功进入轮询结果状态
          if (!res.code) {
            item.taskId = res.data.taskId;
            if (!res.data.binding) {
              item.unboundTask = !res.data.binding;
            }
            // 轮询查询结果
            item.statusTimer = setInterval(() => {
              hxsGet(
                "doconline/open/file/convert/status.html?token=" +
                  localStorage.getItem("token") +
                  "&taskId=" +
                  res.data.taskId
              )
                .then((res) => {
                  if (res.success) {
                    if (res.data.status === 200) {
                      // 成功
                      if (window._czc) {
                        window._czc.push([
                          "_trackEvent",
                          "DFP在线转换",
                          `${this.convertType.name}`,
                          "转换成功"
                        ]);
                      }
                      item.raw.textType = 1;
                      item.raw.handleType = 1;
                      item.percentage = 100;
                      clearInterval(item.statusTimer);
                    } else if (res.data.status === 300) {
                      // 失败
                      if (window._czc) {
                        window._czc.push([
                          "_trackEvent",
                          "DFP在线转换",
                          `${this.convertType.name}`,
                          "转换失败"
                        ]);
                      }
                      item.raw.textType = 2;
                      item.raw.handleType = 2;
                      item.percentage = 100;
                      clearInterval(item.statusTimer);
                      this.changeNum = this.changeNum - 1;
                    } else if (res.data.status === 0) {
                      return;
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }, 2000);
          } else {
            this.showTost = true;
            this.tostText = res.msg;
            const tostTime = setTimeout(() => {
              this.showTost = false;
              clearTimeout(tostTime);
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("catch", err);
          item.raw.textType = 2;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/convert.scss";
#zhjdt {
  height: 6px;
  width: 170px;
  border-radius: 3px;
  background-image: url(../assets/img/trans/zh.webp);
}
</style>
