// 文件信息
export const fileTypes = {
  'pdf-to-word': {
    accept: '.pdf', // 接受上传的文件类型
    action: 'doconline/open/file/upd/presign.html', // 上传地址
    type: 1, // 接受上传的文件type
    name: 'pdf转word', // 下载zip的文件名
    desc: '1次上传最多支持10个文件、支持PDF格式', // 下载描述
    src: require("@/assets/img/home/upload/i-pdf.png"), // 转换文件列表图片
    before: require("@/assets/img/home/upload/i-pdf.png"),
    after: require("@/assets/img/home/upload/i-word.png"),
    zhType: "pdfToWord"
  },
  'pdf-to-excel': {
    accept: '.pdf',
    action: 'doconline/open/file/upd/presign.html',
    type: 1,
    name: 'pdf转excel',
    desc: '1次上传最多支持10个文件、支持PDF格式',
    src: require("@/assets/img/home/upload/i-pdf.png"),
    before: require("@/assets/img/home/upload/i-pdf.png"),
    after: require("@/assets/img/home/upload/i-excel.png"),
    zhType: "pdfToExcel"
  },
  'pdf-to-ppt': {
    accept: '.pdf',
    action: 'doconline/open/file/upd/presign.html',
    type: 1,
    name: 'pdf转ppt',
    desc: '1次上传最多支持10个文件、支持PDF格式',
    src: require("@/assets/img/home/upload/i-pdf.png"),
    before: require("@/assets/img/home/upload/i-pdf.png"),
    after: require("@/assets/img/home/upload/i-ppt.png"),
    zhType: "pdfToPpt"
  },
  'pdf-to-img': {
    accept: '.pdf',
    action: 'doconline/open/file/upd/presign.html',
    type: 1,
    name: 'pdf转img',
    desc: '1次上传最多支持10个文件、支持PDF格式',
    src: require("@/assets/img/home/upload/i-pdf.png"),
    before: require("@/assets/img/home/upload/i-pdf.png"),
    after: require("@/assets/img/home/upload/i-img.png"),
    zhType: "pdfToJpg"
  },
  'word-to-pdf': {
    accept: '.doc,.docx',
    action: 'doconline/open/file/upd/presign.html',
    type: 2,
    name: 'word转pdf',
    desc: '1次上传最多支持10个文件、支持.doc,.docx格式',
    src: require("@/assets/img/home/upload/i-word.png"),
    before: require("@/assets/img/home/upload/i-word.png"),
    after: require("@/assets/img/home/upload/i-pdf.png"),
    zhType: "wordToPdf"
  },
  'excel-to-pdf': {
    accept: '.xlsx,.xls',
    action: 'doconline/open/file/upd/presign.html',
    type: 2,
    name: 'excel转pdf',
    desc: '1次上传最多支持10个文件、支持.xlsx,.xls格式',
    src: require("@/assets/img/home/upload/i-excel.png"),
    before: require("@/assets/img/home/upload/i-excel.png"),
    after: require("@/assets/img/home/upload/i-pdf.png"),
    zhType: "excelToPdf"
  },
  'ppt-to-pdf': {
    accept: '.ppt,.pptx',
    action: 'doconline/open/file/upd/presign.html',
    type: 2,
    name: 'ppt转pdf',
    desc: '1次上传最多支持10个文件、支持.ppt,.pptx格式',
    src: require("@/assets/img/home/upload/i-ppt.png"),
    before: require("@/assets/img/home/upload/i-ppt.png"),
    after: require("@/assets/img/home/upload/i-pdf.png"),
    zhType: "pptToPdf"
  },
  'cad-to-pdf': {
    accept: '.dwg,.dxf,.dwt',
    action: 'doconline/open/file/upd/presign.html',
    type: 3,
    name: 'cad转pdf',
    desc: '1次上传最多支持10个文件、支持.dwg,.dxf,.dwt格式',
    src: require("@/assets/img/home/upload/i-cad.png"),
    before: require("@/assets/img/home/upload/i-cad.png"),
    after: require("@/assets/img/home/upload/i-pdf.png"),
    zhType: "cadToPdf"
  },
  'ofd-to-pdf': {
    accept: '.ofd',
    action: 'doconline/open/file/upd/presign.html',
    type: 5,
    name: 'ofd转pdf',
    desc: '1次上传最多支持10个文件、支持.ofd格式',
    src: require("@/assets/img/home/upload/i-ofd.png"),
    before: require("@/assets/img/home/upload/i-ofd.png"),
    after: require("@/assets/img/home/upload/i-pdf.png"),
    zhType: "ofdToPdf"
  },
  'img-to-pdf': {
    accept: '.jpg,.jpeg,.bmp,.png,.tif,.tiff,.webp',
    action: 'doconline/open/file/upd/presign.html',
    type: 4,
    name: 'img转pdf',
    desc: '1次上传最多支持10个文件、支持.jpg,.jpeg,.bmp,.png,.tif,.tiff,.webp格式',
    src: require("@/assets/img/home/upload/i-img.png"),
    before: require("@/assets/img/home/upload/i-img.png"),
    after: require("@/assets/img/home/upload/i-pdf.png"),
    zhType: "imgToPdf"
  }
}
