<template>
  <div>
    <!-- PDF在线转换已处理xxx -->
    <div class="handle-wrap">
      <div class="handle">
        <h3 class="handle-title">
          <span>PDF在线转换已累计处理文件</span>
          <span class="handle-title-num">{{ total }}</span>
          <span>个</span>
        </h3>
        <div class="handle-list">
          <div
            class="handle-list-item"
            v-for="item in handleList"
            :key="item.id"
          >
            <div class="item-top">
              <div :class="['top-pic', item.id === 6 ? 'pic6' : '']">
                <img :src="item.pic" alt="" />
              </div>
              <div class="top-desc">{{ item.name }}</div>
            </div>
            <div class="item-bottom">
              <div>{{ item.desc1 }}</div>
              <div>{{ item.desc2 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 评分 -->
    <div class="star-wrap">
      <div class="star-content">
        <span class="star-name">给此工具评分</span>
        <el-tooltip
          v-for="item in starItem"
          :key="item.id"
          class="item"
          :open-delay="130"
          :disabled="tooltipDis"
          effect="dark"
          :content="item.text"
          placement="top"
        >
          <i
            class="el-icon-star-on star-voil"
            :class="item.class"
            @mouseenter="mouseEnter(item)"
            @mouseleave="mouseLeave(item)"
            @click="starClick(item)"
          />
        </el-tooltip>
        <span class="star-score" style="margin-left: 8px; color: #343434">{{
          score
        }}</span>
        <span class="star-score" style="margin: 0 4px">/</span>
        <span class="star-score">5</span>
        <span class="star-">-</span>
        <span class="star-score" style="color: #343434"
          >{{ scoreTimes }}次投票</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { handleList } from "@/utils/menu";
import { historyPost, hxsGet } from "@/apis/index";
import AES from "@/utils/ase.js";
export default {
  data() {
    return {
      handleList,
      starValue: 0,
      starItem: [
        {
          id: 1,
          text: "很差",
          class: ""
        },
        {
          id: 2,
          text: "不行",
          class: ""
        },
        {
          id: 3,
          text: "一般",
          class: ""
        },
        {
          id: 4,
          text: "很好",
          class: ""
        },
        {
          id: 5,
          text: "非常好",
          class: ""
        }
      ],
      score: 5,
      scoreTimes: 1755666,
      isHover: true,
      tooltipDis: false,
      total: localStorage.getItem("totalNum")
    };
  },
  created() {
    this.getStar();
    this.getTotal();
  },
  methods: {
    mouseEnter(item) {
      if (this.isHover) {
        this.starItem.forEach((i) => {
          i.id <= item.id ? (i.class = "star-hover") : (i.class = "");
        });
      }
    },
    mouseLeave() {
      if (this.isHover) {
        this.starItem.forEach((i) => {
          i.class = "";
        });
      }
    },
    starClick(item) {
      if (this.isHover) {
        this.starItem.forEach((i) => {
          i.id <= item.id ? (i.class = "star-hover") : (i.class = "");
        });
        this.addStar({ score: item.id });
      }
    },
    // 获取平均分
    getStar() {
      // historyGet("/score/get").then((res) => {
      //   if (res.code === 0) {
      //     this.score = res.data.score;
      //     this.scoreTimes = res.data.scoreTimes;
      //   }
      // });
    },
    // 获取处理文件次数
    getTotal() {
      if (new Date().getTime() - localStorage.getItem("getTotalTime") > 5000) {
        hxsGet("doconline/open/file/convert/count.html").then((res) => {
          localStorage.setItem("getTotalTime", new Date().getTime());
          localStorage.setItem("totalNum", res.data);
          this.total = res.data;
        });
      }
    },
    // 新增评分
    addStar(params) {
      const rObj = AES.encrypt(JSON.stringify(params));

      historyPost(`/score/add`, rObj, {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => {
          const res = JSON.parse(AES.decrypt(response));

          if (res.code === 0) {
            this.isHover = false;
            this.tooltipDis = true;
          }
        });
    },
    // 数组格式化
    toThousands(num) {
      const result = [];
      let counter = 0;

      num = (num || 0).toString().split("");
      for (let i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i !== 0) {
          result.unshift(",");
        }
      }

      return result.join("");
    }
  }
};
</script>

<style lang="scss" scoped>
.star-hover {
  color: #ffbd64 !important;
}
.handle-wrap {
  height: 667px;
  background-color: #fff;
  border-bottom: 1px solid rgba(40, 40, 40, 0.2);
  .handle {
    width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    height: 667px;
    padding-top: 62px;
    box-sizing: border-box;
    .handle-title {
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      color: #343434;
      line-height: 35px;
      .handle-title-num {
        font-size: 40px;
        font-weight: bold;
      }
    }
    .handle-list {
      margin-top: 70px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-around;
      .handle-list-item {
        width: 30%;
        height: 162px;
        margin-bottom: 60px;
        .item-top {
          height: 100px;
          margin: 0 auto;
          .top-pic {
            width: 54px;
            height: 54px;
            margin: 0 auto;
          }
          .pic6 {
            width: 100px;
            height: 100px;
            margin: 0 auto;
          }
          .top-desc {
            text-align: center;
            margin-top: 20px;
          }
        }
        .item-bottom {
          margin-top: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
}
.star-wrap {
  display: none;
  height: 110px;
  .star-content {
    width: 1200px;
    min-width: 1200px;
    height: 110px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .star-name {
      font-size: 16px;
      font-weight: 400;
      color: #343434;
      margin-right: 15px;
    }
    .star-voil {
      color: #c7c7c7;
      font-size: 26px;
      cursor: pointer;
    }
    .star-score {
      font-size: 16px;
      font-weight: 400;
      color: #a3a3a3;
    }
    .star- {
      margin: 0 7px;
    }
  }
}
</style>
<style>
.el-rate__icon {
  font-size: 22px !important;
}
</style>
